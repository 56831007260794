@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Raleway:500|Libre+Baskerville:500|Delius:500");

h1 {
  font-size: 2.5rem;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  line-height: 2.75rem;
}

label {
  font-weight: 500;
  font-family: "Raleway", sans-serif;
}

h2 {
  font-size: 1.375rem;
  font-family: "Raleway", sans-serif;
  line-height: 1.75rem;
  font-weight: 500;

  padding: 2rem;
  /* background-color: #225baf; */
}

h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
html,
body,
#root,
.App {
  min-height: 100%;

  background: rgb(84, 59, 194);

  background-image: linear-gradient(
    142deg,
    rgba(84, 59, 194, 1) 0%,
    rgba(142, 28, 153, 1) 55%,
    rgba(113, 13, 122, 1) 100%
  );
}
.frosted {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}
#app {
  padding-top: 2rem;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 5%;
}

input {
  background-color: #e5e7eb;
  border-radius: 5px;
  padding: 5px;
}

.invert {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

@layer base {
  p {
    @apply text-white;
  }
  a {
    @apply text-yellow-400 underline;
  }

  h2 {
    @apply text-yellow-500  font-bold;
  }
  label {
    @apply text-white mr-3;
  }
}
